import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import EventListItem from "../components/ListItems/EventListItem";
import Title from "../components/Title";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const EventList = ({pageContext, data, location}) => {
  const {
    breadcrumb: {crumbs},
    currentPage,
    numPages,
    basePath
  } = pageContext

  return (
    <Layout>
      <Metas title="Agenda"/>
      <section className="section page-breadcrumb is-small has-background-light">
        <div className="container">
            <CustomBreadcrumb crumbs={crumbs}/>
        </div>
      </section>
      <section className="section page-content">
        <div className="container">
          <Title title="Agenda" subtitle="Dates à venir" className="has-text-centered"/>
            {data.events.nodes.length > 0 ? (
              <>
                <div className="columns is-multiline">
                  {data.events.nodes.map((node, index) => (
                    <div key={index} className="column is-6 is-4-desktop">
                      <EventListItem content={node} />
                    </div>
                  ))}
                </div>
                <Pagination
                  path={location.pathname}
                  currentPage={currentPage}
                  numPages={numPages}
                  basePath={basePath}
                />
              </>
            ) : (
              <p>Aucun &eacute;v&egrave;nement pr&eacute;vu dans les prochains jours.</p>
            )}
        </div>
      </section>
    </Layout>
  )
}

export default EventList

export const query = graphql`
    query eventListQuery($skip: Int!, $limit: Int!) {
        events: allNodeEvenement(
            filter: {
                status: {eq: true}, 
                is_forthcoming: {eq: true}
            }
            sort: {fields: field_date___value, order: ASC}
            limit: $limit,
            skip: $skip
        ) {
            nodes {
                id
                title
                dates: field_date {
                    value(formatString: "DD/MM/Y")
                    end_value(formatString: "DD/MM/Y")
                }
                emplacement: field_emplacement
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 480
                                    height: 270
                                    quality: 100
                                    transformOptions: {cropFocus: CENTER}
                                    layout: CONSTRAINED
                                )
                            }
                        }
                    }
                }
                path {
                    alias
                }
            }
        }
    }`
